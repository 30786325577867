import React, { CSSProperties } from "react"

import "./Separator.scss"

interface PropTypes {
  color?: CSSProperties["color"]
}

//Add a noMargin to the children
const Separator = ({ color }: PropTypes) => {
  return (
    <hr className="separator" style={color ? { borderColor: color } : {}} />
  )
}

export default Separator
