import { ArticleData } from "@local-types/Article"
import React from "react"
import { Helmet } from "react-helmet"

function ArticlesSEO({ devToArticle }: { devToArticle: ArticleData }) {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            { "@context": "https://schema.org", 
            "@type": "Article",
            "headline": "${devToArticle.title}",
            "image": "${devToArticle.coverImage}",
            "author": "${devToArticle.user.username}",
            "keywords": "${devToArticle.tags}", 
            "wordcount": "1120",
           "publisher": {
               "@type": "Organization",
               "name": "Kezios",
               "logo": {
                 "@type": "Kezios",
                 "url": "https://www.kezios.fr/Kezios-Logo.png"
               }
             },
            "url": "http://www.kezios.fr/articles/${devToArticle.slug}",
            "datePublished": "${devToArticle.createdAt}",
            "description": "${devToArticle.description}",       
          `}
        </script>
      </Helmet>
    </>
  )
}

export default ArticlesSEO
