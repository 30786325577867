import Link from "@components/Gatsby/Link/Link"
import Pills from "@components/ui/Pills/Pills"
import { Body, Heading } from "@components/ui/Typography/Typography"
import { upperFirstLetterCase } from "@utils/string"
import React from "react"

import "./ArticleHero.scss"

export type ArticleHeroPropsType = {
  title: string
  tags: string[]
}

const ArticleHero = ({ title, tags }: ArticleHeroPropsType) => {
  return (
    <section className="article-hero">
      <div className="article-hero-container">
        <Body className="article-breadcrumb">
          <Link to="/">Accueil</Link> &#62; <Link to="/articles">Blog</Link>{" "}
          &#62; <b>{title}</b>
        </Body>
        <div className="article-hero-infos">
          <Heading variant="h3">{title}</Heading>
          <div className="article-hero-tags-container">
            {tags.map((tag: string, index: number) => (
              <Pills
                key={`${index}-${tag}`}
                className={`article-hero-tag tag-${tag}`}
              >
                {upperFirstLetterCase(tag)}
              </Pills>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArticleHero
