import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout/layout"

import "./article-detail.scss"
import CenteredContent from "../../components/CenteredContent/CenteredContent"
import SEO from "../../components/SEO/SEO"
import { ArticleData } from "@local-types/Article"
import ArticleHero from "@landingsParts/BlogArticle/ArticleHero/ArticleHero"
import ArticleAuthor from "@components/ArticleAuthor/ArticleAuthor"
import { getUserIdFromDevtoUsername } from "@components/ui/Avatar/Avatar"
import Separator from "@components/ui/Separator/Separator"
import ArticlesSEO from "@components/SEO/ArticlesSEO"

type PropTypes = { data: { devToArticle: ArticleData } }

export default function ArticleDetails({ data }: PropTypes) {
  const {
    title,
    description,
    tags,
    user,
    publishedTimestamp,
    readingTimeMinutes,
    bodyHtml: html,
  } = data.devToArticle
  const authorId = getUserIdFromDevtoUsername(user.username)

  return (
    <Layout>
      <SEO title={`Kezios - ${title}`} description={description} lang={"fr"} />
      <ArticlesSEO devToArticle={data.devToArticle} />
      <ArticleHero title={title} tags={tags} />
      <div className="article-detail">
        <ArticleAuthor
          authorId={authorId}
          published_timestamp={publishedTimestamp}
          reading_time_minutes={readingTimeMinutes}
          variant="medium"
          className="article-detail-author"
        />
        <Separator />
        <CenteredContent className="article-detail-content">
          <article
            className="article-detail-message-content "
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </CenteredContent>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectDetails($id: String) {
    devToArticle(id: { eq: $id }) {
      bodyHtml: body_html
      bodyMarkdown: body_markdown
      coverImage: cover_image
      createdAt: created_at
      title
      description
      readingTimeMinutes: reading_time_minutes
      publishedTimestamp: published_timestamp
      user {
        username
      }
      slug
      tags
      id
    }
  }
`
